@import "./colors.less";

@font-color: @grey-900;

.inter-font() {
  font-family: "Inter";
  font-style: normal;
}

.mono-font() {
  font-family: "Roboto Mono", monospace;
  font-style: normal;
}

.inter-bold() {
  .inter-font();
  font-weight: 700;
}

.inter-semibold() {
  .inter-font();
  font-weight: 600;
}

.inter-medium() {
  .inter-font();
  font-weight: 500;
}

.inter-regular() {
  .inter-font();
  font-weight: 400;
}

.mono-medium() {
  .mono-font();
  font-weight: 500;
}

.mono-regular() {
  .mono-font();
  font-weight: 400;
}
