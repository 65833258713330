.dropdown-shadow {
  box-shadow: 0px 2px 4px rgba(27, 29, 33, 0.06),
    0px 8px 16px rgba(27, 29, 33, 0.08);
}
.popup-shadow {
  box-shadow: 0px 20px 48px rgba(27, 29, 33, 0.24);
}
.select .name {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.select.disabled {
  cursor: not-allowed;
  color: rgb(var(--color-grey-300));
}
.select.hasError {
  color: rgb(var(--color-error-600));
}
.select.hasError :global .select__control {
  border-color: rgb(var(--color-error-500));
}
.select.hasError :global .select__control.select__control--is-focused {
  box-shadow: 0px 0px 0px 3px rgb(var(--color-error-100));
}
.select :global .select__control {
  width: 100%;
  display: block;
  background-color: rgb(var(--color-background));
  outline: none;
  border: 1px solid rgb(var(--color-grey-200));
  border-radius: 4px;
  transition-duration: 100ms;
  transition-timing-function: ease;
  transition-property: color, border-color, background-color, box-shadow;
  overflow: hidden;
  height: unset;
  min-height: unset;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.select :global .select__control:hover {
  border-color: rgb(var(--color-grey-200));
}
.select :global .select__control.select__control--is-focused {
  border-color: rgb(var(--color-primary-500));
  box-shadow: 0px 0px 0px 3px rgb(var(--color-primary-100));
}
.select
  :global
  .select__control.select__control--menu-is-open
  .select__dropdown-indicator {
  transform: scaleY(-1);
}
.select
  :global
  .select__control
  .select__indicators
  .select__dropdown-indicator {
  color: rgb(var(--color-grey-400));
  padding: 0;
  margin-right: 12px;
}
.select :global .select__control .select__value-container {
  margin: 0;
  padding: 6px 12px;
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgb(var(--color-grey-900));
}

.select :global .select__control .select__value-container input::placeholder {
  line-height: 20px;
}

.select
  :global
  .select__control
  .select__value-container.select__value-container--is-multi.select__value-container--has-value {
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-right: 10px;
}
.select :global .select__control .select__value-container .select__placeholder {
  margin: 0;
  color: rgb(var(--color-grey-400));
  position: absolute;
}
.select
  :global
  .select__control
  .select__value-container
  .select__single-value {
  margin: 0;
  position: absolute;
}
.select :global .select__control .select__value-container .select__multi-value {
  background-color: rgb(var(--color-primary-50));
  margin: 0;
  margin-right: 4px;
  margin-bottom: 2px;
}
.select
  :global
  .select__control
  .select__value-container
  .select__multi-value
  .select__multi-value__label {
  color: rgb(var(--color-primary-600));
  margin: 0;
  padding: 0;
  padding-left: 8px;
  font-size: 12px;
}
.select
  :global
  .select__control
  .select__value-container
  .select__multi-value
  .select__multi-value__remove {
  color: rgb(var(--color-primary-600));
  background-color: inherit;
}
.select :global .select__menu {
  border: none;
  background: rgb(var(--color-background));
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(27, 29, 33, 0.06),
    0px 8px 16px rgba(27, 29, 33, 0.08);
  overflow: hidden;
}
.select :global .select__menu .select__menu-list {
  padding: 0;
}

.select
  :global
  .select__menu
  .select__menu-list
  .select__group
  .select__group-heading {
  font-size: 11px;
  font-weight: 600;
}

.select :global .select__menu .select__option {
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
  color: rgb(var(--color-grey-800));
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 400;
}
.select :global .select__menu .select__option.select__option--is-focused {
  cursor: pointer;
  background: rgb(var(--color-grey-50));
}
.select :global .select__menu .select__option.select__option--is-selected {
  background: rgb(var(--color-primary-50));
  color: rgb(var(--color-primary-600));
}
.select :global .select__menu .select__option.select__option--is-disabled {
  color: rgb(var(--color-grey-400));
}
.select :global .select__input-container {
  margin: 0;
  padding: 0;
}
