.dropdown-shadow {
  box-shadow: 0px 2px 4px rgba(27, 29, 33, 0.06),
    0px 8px 16px rgba(27, 29, 33, 0.08);
}
.popup-shadow {
  box-shadow: 0px 20px 48px rgba(27, 29, 33, 0.24);
}
.container label {
  transition-duration: 100ms;
  transition-timing-function: ease;
  transition-property: color;
}
.container .inputHeader {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}
.container .inputWrapper {
  position: relative;
}
.container input,
.container textarea {
  width: 100%;
  display: block;
  background-color: rgb(var(--color-white));
  outline: none;
  border: 1px solid rgb(var(--color-grey-200));
  border-radius: 4px;
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 6px 12px;
  color: rgb(var(--color-default-font));
  transition-duration: 100ms;
  transition-timing-function: ease;
  transition-property: color, border-color, background-color, box-shadow;
  overflow: hidden;
}
.container input::placeholder,
.container textarea::placeholder {
  color: rgb(var(--color-grey-400));
  opacity: 1;
  line-height: 20px;
}
.container .icon {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 9px;
}
.container.hasPrefix input,
.container.hasPrefix textarea {
  padding-left: 1.8em;
}
.container.hasPrefix.hasPrefix2 input,
.container.hasPrefix.hasPrefix2 textarea {
  padding-left: 2.5em;
}
.container.hasPrefix.hasPrefix3 input,
.container.hasPrefix.hasPrefix3 textarea {
  padding-left: 3.2em;
}
.container.hasLeftIcon input,
.container.hasLeftIcon textarea {
  padding-left: 32px;
}
.container.hasRightIcon input,
.container.hasRightIcon textarea {
  padding-right: 32px;
}
.container .prefix {
  position: absolute;
  top: 7px;
  left: 1em;
  color: rgb(var(--color-default-font));
}
.container .leftIcon {
  color: rgb(var(--color-grey-300));
  left: 12px;
}
.container .rightIcon {
  right: 12px;
}
.container:focus-within label,
.container:focus-within .leftIcon {
  color: rgb(var(--color-primary-600));
}
.container:focus-within input,
.container:focus-within textarea {
  border-color: rgb(var(--color-primary-500));
  box-shadow: 0px 0px 0px 3px rgb(var(--color-primary-100));
}
.container .error {
  color: rgb(var(--color-error-600));
}
.container .warningLabel {
  color: rgb(var(--color-warning-600));
}
.large input,
.large textarea {
  padding: 12px 16px;
}
.large .icon {
  top: 14px;
}
.large .prefix {
  top: 12px;
}
.disabledState input,
.disabledState textarea {
  background-color: rgb(var(--color-grey-50));
  border-color: rgb(var(--color-grey-100));
}
.disabledState label {
  color: rgb(var(--color-grey-300));
}
.disabledState .leftIcon {
  color: rgb(var(--color-grey-200));
}
.disabledState .prefix {
  color: rgb(var(--color-grey-400));
}
.warningState label {
  color: rgb(var(--color-warning-600));
}
.warningState input,
.warningState textarea {
  border-color: rgb(var(--color-warning-500));
  padding-right: 32px;
}
.warningState:focus-within label {
  color: rgb(var(--color-warning-600));
}
.warningState:focus-within .leftIcon {
  color: rgb(var(--color-grey-300));
}
.warningState:focus-within input,
.warningState:focus-within textarea {
  border-color: rgb(var(--color-warning-500));
  box-shadow: 0px 0px 0px 3px rgb(var(--color-warning-100));
}
.warningState .rightIcon {
  color: rgb(var(--color-warning-600));
  right: 12px;
}
.errorState label {
  color: rgb(var(--color-error-600));
}
.errorState input,
.errorState textarea {
  border-color: rgb(var(--color-error-500));
  padding-right: 32px;
}
.errorState:focus-within label {
  color: rgb(var(--color-error-600));
}
.errorState:focus-within .leftIcon {
  color: rgb(var(--color-grey-300));
}
.errorState:focus-within input,
.errorState:focus-within textarea {
  border-color: rgb(var(--color-error-500));
  box-shadow: 0px 0px 0px 3px rgb(var(--color-error-100));
}
.errorState .rightIcon {
  color: rgb(var(--color-error-600));
  right: 12px;
}
.errorState .error {
  color: rgb(var(--color-error-600));
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.successState label {
  color: rgb(var(--color-success-600));
}
.successState input,
.successState textarea {
  border-color: rgb(var(--color-success-500));
  padding-right: 32px;
}
.successState:focus-within label {
  color: rgb(var(--color-success-600));
}
.successState:focus-within .leftIcon {
  color: rgb(var(--color-grey-200));
}
.successState:focus-within input,
.successState:focus-within textarea {
  border-color: rgb(var(--color-success-500));
  box-shadow: 0px 0px 0px 3px rgb(var(--color-success-100));
}
.successState .rightIcon {
  color: rgb(var(--color-success-600));
  right: 12px;
}
.suffix {
  position: absolute;
  top: 7px;
  color: rgb(var(--color-default-font));
  right: 0.75rem;
}
:is(.errorState, .successState, .warningState) .suffix {
  right: 2rem;
}
