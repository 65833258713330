@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* pre t10 */
  :root {
    --font-family-default: "Inter";
    --font-family-mono: "Roboto Mono", monospace;
    --border-radius-large: 8px;
    --border-radius-medium: 4px;
    --border-radius-small: 2px;

    /* spacing */
    --spacing-4: 4px;
    --spacing-8: 8px;
    --spacing-12: 12px;
    --spacing-16: 16px;
    --spacing-20: 20px;
    --spacing-24: 24px;
    --spacing-32: 32px;
  }

  .text-display-xl,
  .text-display-lg,
  .text-display-md {
    letter-spacing: -1.2px;
  }

}

@layer components {
  .searchInput::-webkit-search-decoration,
  .searchInput::-webkit-search-cancel-button,
  .searchInput::-webkit-search-results-button,
  .searchInput::-webkit-search-results-decoration {
    display: none;
  }

  .date-picker-container .react-datepicker__aria-live {
    @apply hidden;
  }
  .date-picker-container .react-datepicker__header {
    @apply rounded-xl border-none bg-white font-default;
  }
  .date-picker-container .react-datepicker__day-names {
    @apply px-xs h-5xl grid grid-cols-7 whitespace-nowrap text-sm font-medium;
  }
  .date-picker-container .react-datepicker__month {
    @apply p-xs gap-y-xxs grid text-center;
  }
  .date-picker-container .react-datepicker__week {
    @apply h-5xl grid grid-cols-7 whitespace-nowrap;
  }

  /* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

/* pre t10 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-collapse: unset;
  border-spacing: 0px;
  font-family: var(--font-family-default);
}
