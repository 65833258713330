.dropdown-shadow {
  box-shadow: 0px 2px 4px rgba(27, 29, 33, 0.06),
    0px 8px 16px rgba(27, 29, 33, 0.08);
}
.popup-shadow {
  box-shadow: 0px 20px 48px rgba(27, 29, 33, 0.24);
}
.control {
  display: flex;
  min-height: 16px;
  margin: 8px 0;
  color: rgb(var(--color-grey-900));
}
.control.compact {
  margin: 0;
}
.control .input {
  margin-right: 8px;
}
.checkbox {
  min-width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  outline: 0;
  background: 0;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(var(--color-grey-200));
  border-radius: 2px;
  cursor: pointer;
  transition-duration: 100ms;
  transition-timing-function: ease;
  transition-property: background-color, border-color, box-shadow,
    background-image, background-position;
}
.checkbox:focus {
  box-shadow: 0px 0px 0px 3px rgb(var(--color-primary-100));
}
.checkbox.checked {
  background-color: rgb(var(--color-primary-600));
  border-color: rgb(var(--color-primary-600));
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik05IDFMMy4zMzMzMyA3TDEgNC42OTIzMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.radio {
  min-width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  outline: 0;
  background: 0;
  border-radius: 8px;
  border: 1px solid rgb(var(--color-grey-200));
  cursor: pointer;
  transition-duration: 100ms;
  transition-timing-function: ease;
  transition-property: background-color, border-color, box-shadow,
    background-image, background-position;
  position: relative;
}
.radio:focus {
  box-shadow: 0px 0px 0px 3px rgb(var(--color-primary-100));
}
.radio.checked {
  border: 2px solid rgb(var(--color-primary-600));
}
.radio .radioDot {
  min-width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgb(var(--color-primary-600));
  position: absolute;
  left: 3px;
  top: 3px;
}
.disabled {
  color: rgb(var(--color-grey-500));
}
.disabled .checkbox {
  cursor: inherit;
  border-color: rgb(var(--color-grey-100));
}
.disabled .checkbox.checked {
  border-color: rgb(var(--color-grey-200));
  background-color: rgb(var(--color-grey-200));
}
.disabled .radio {
  cursor: inherit;
  border-color: rgb(var(--color-grey-200));
}
.disabled .radio .radioDot {
  background-color: rgb(var(--color-grey-200));
}
