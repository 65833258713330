@import "style/typography.less";
@import "style/border-radius.less";

.container {
  margin-top: 12px;
  width: 100%;
  height: 100%;
}

.chart {
  .yAxisTick {
    .inter-medium();
    fill: @grey-300;
    font-size: 8px;
  }

  .xAxisTick {
    .inter-medium();
    fill: @grey-700;
    font-size: 11px;
  }

  .tooltipCursor {
    stroke: @grey-800;
  }

  .referenceMarker {
    :global {
      .recharts-reference-line-line {
        stroke: @grey-600;
      }
    }
  }

  :global {
    // x-axis
    .recharts-xAxis {
      .recharts-cartesian-axis-line {
        stroke: @grey-100;
        stroke-width: 1px;
      }
    }

    // Grid
    .recharts-cartesian-grid {
      line {
        stroke: @grey-100;
        stroke-width: 1px;
        stroke-dasharray: 4px 4px;

        &:last-child,
        &:first-child {
          display: none;
        }
      }
    }

    .recharts-tooltip-wrapper {
      .recharts-default-tooltip {
        background: @grey-900 !important;
        .border-radius-large() !important;
        border: none !important;
        padding: 4px 8px !important;
        .inter-font();

        .recharts-tooltip-label {
          color: @white;
        }

        .recharts-tooltip-item-list li {
          padding: 0 !important;
          color: @white !important;
        }
      }
    }
  }
}

.tooltipText {
  color: @white;
}

.legend {
  .inter-semibold();
  font-size: 11px;
  text-transform: uppercase;
  color: rgb(var(--color-grey-600));
  list-style: none;
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 20px;
  }

  .legendLayer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .legendLabel {
      display: flex;
      align-items: center;

      .dot {
        margin-right: 6px;
        height: 12px;
        min-width: 12px;
        border-radius: 50%;
      }
    }

    .legendTotal {
      .inter-medium();
      font-size: 12px;
      text-transform: none;
      color: rgb(var(--color-grey-600));
      padding-left: 18px;
    }
  }
}
