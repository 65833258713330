@import "style/colors.less";
@import "style/border-radius.less";

@keyframes shine-lines {
  0% {
    background-position: 0px;
  }

  100% {
    background-position: 600px;
  }
}

.block {
  width: 100%;
  height: 100%;
  .border-radius-large();

  background-image: linear-gradient(
    90deg,
    @grey-50 100px,
    lighten(@grey-50, 50%) 300px,
    @grey-50 500px
  );
  background-size: 600px;
  animation: shine-lines 0.7s infinite linear;
}

.text {
  height: 12px;
  border-radius: 6px;
  margin: 6px 0;

  &.width66 {
    width: 66%;
  }
  &.width75 {
    width: 75%;
  }
  &.width80 {
    width: 80%;
  }
  &.width100 {
    width: 100%;
  }
  &.widthDefault {
    width: 100%;
    max-width: 300px;
  }
  &.sizeSmall {
    height: 8px;
    margin: 4px 0;
  }

  background-image: linear-gradient(
    90deg,
    @grey-50 100px,
    lighten(@grey-50, 50%) 300px,
    @grey-50 500px
  );
  background-size: 600px;
  animation: shine-lines 0.7s infinite linear;
}

.graphSkeleton {
  margin: 12px 0;
  overflow: hidden;

  table {
    width: 100%;
  }

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 8px;

    background-image: linear-gradient(
      90deg,
      @grey-50 100px,
      lighten(@grey-50, 50%) 300px,
      @grey-50 500px
    );
    background-size: 600px;
    animation: shine-lines 0.7s infinite linear;
  }

  .textSkeletonLong {
    height: 4px;
    width: 73px;
    margin: 0 8px 0 0;
  }

  .textSkeletonMedium {
    height: 4px;
    width: 26px;
    margin: 0;
  }

  .textSkeletonShort {
    height: 4px;
    width: 16px;
    margin: 0 8px 0 0;
  }

  .firstRow {
    padding-top: 6px;
    display: flex;
    height: 13px;
  }

  .firstRowWithTotals {
    height: 30px;
  }

  .dashedRow,
  .solidRow {
    display: flex;
    align-items: center;
    height: 51px;
  }

  .rowWithTotals {
    height: 47px;
  }

  .dashedLine,
  .solidLine,
  .column {
    width: 88px;
    flex-basis: 0;
    flex-grow: 1;
  }

  .dashedLine {
    border-top: 1px dashed @grey-100;
  }

  .solidLine {
    border-top: 1px solid @grey-100;
  }

  .lastRow {
    display: flex;
    height: 4px;
    margin-top: -16px;

    .column {
      display: flex;
      justify-content: center;
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
}
