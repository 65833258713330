.dropdown-shadow {
  box-shadow: 0px 2px 4px rgba(27, 29, 33, 0.06),
    0px 8px 16px rgba(27, 29, 33, 0.08);
}
.popup-shadow {
  box-shadow: 0px 20px 48px rgba(27, 29, 33, 0.24);
}
.popper[data-placement] {
  margin: 0;
}
.datepickerWrapper {
  width: 100%;
}
.datepickerWrapper :global(.react-datepicker-wrapper) {
  width: 100%;
}

.container {
  display: inline-block;
  border: none;
  background: rgb(var(--color-white));
  padding: 12px;
  box-shadow: 0px 2px 4px rgba(27, 29, 33, 0.06),
    0px 8px 16px rgba(27, 29, 33, 0.08);
  border-radius: 4px;
}
.container :global .react-datepicker__header--custom {
  padding: 0;
  background: none;
  border: none;
}
.container :global .react-datepicker__month {
  margin: 0;
}
.header {
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .monthButtons {
  display: flex;
}
.header .monthButtons .monthButton {
  text-decoration: none;
  cursor: pointer;
  min-width: unset;
}
.header .monthButtons .monthButton:nth-child(2) {
  width: 10px;
  color: rgb(var(--color-grey-400));
}
.day {
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 500;
  color: rgb(var(--color-grey-900));
}
.day:hover {
  background: rgb(var(--color-grey-50));
  color: rgb(var(--color-grey-900));
}
.day:global(.react-datepicker__day--outside-month) {
  color: rgb(var(--color-grey-400));
}
.day:global(.react-datepicker__day--today) {
  color: rgb(var(--color-primary-600));
}
.day:global(.react-datepicker__day--keyboard-selected),
.day:global(.react-datepicker__day--selected) {
  background: rgb(var(--color-primary-500));
  color: rgb(var(--color-white));
  outline: none;
}
.day:global(.react-datepicker__day--disabled) {
  background: unset;
  color: rgb(var(--color-grey-600));
}
.weekdayLabel {
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 500;
  color: rgb(var(--color-grey-600));
}
.day,
.weekdayLabel {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin: 0 2px;
  font-size: 12px;
}
.day:first-child,
.weekdayLabel:first-child,
.day:last-child,
.weekdayLabel:last-child {
  margin: 0;
}
