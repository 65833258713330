@import "../../../style/animation";
@import "../../../style/colors";
@import "../../../style/border-radius";
@import "../../../style/typography";
@import "../../../style/shadows";

.cards {
  display: flex;
  flex-direction: column;

  .card {
    background: @white;
    background-color: @background;
    color: @font-color;
    border: 1px solid @grey-100;
    .border-radius-large();
    padding: 8px 12px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.selected {
      background: @primary-50;
      border: 1px solid @primary-100;
    }

    &.clickable {
      cursor: pointer;
      text-decoration: none;
      .micro-interaction(box-shadow;);
      &:hover {
        .dropdown-shadow();
        .content {
          color: @primary-600;
        }
        background-color: @primary-50;
      }
    }

    .content {
      font-size: 12px;
      line-height: 20px;
      .inter-regular();
      color: @grey-900;
      margin-right: auto;

      &.selected {
        color: @primary-600;
      }
    }
    .icon {
      display: flex;
    }
  }
}
