@import "../../../style/animation.less";
@import "../../../style/typography.less";

.tableContainer {
  margin-bottom: 12px;
}

.table {
  min-width: calc(100% + 24px);
  font-size: 12px;
  margin: 0 -12px;
  border-collapse: collapse;

  tbody:not(.noBottomBorder) {
    tr:last-child,
    .expanded:last-child {
      td {
        border-bottom: 1px solid @grey-100;
      }
    }
  }

  td {
    border-top: 1px solid @grey-100;

    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
    &.textWrap {
      word-break: break-word;
      min-width: 150px;
    }
  }

  .left .cellContainer,
  .left.cellContainer {
    padding: 12px 10px 12px 0;
  }

  .right .cellContainer,
  .right.cellContainer {
    padding: 12px 0 12px 10px;
  }

  a.cellContainer {
    display: inline-block;
    min-width: 100%;
    text-decoration: none;
  }

  td,
  th {
    padding: 0;

    &:first-child,
    &:last-child {
      padding: 10px 0;
      width: 12px;
    }

    &:nth-child(2) {
      &.cellContainer,
      .cellContainer {
        padding-left: 0;
      }
    }

    &:nth-last-child(2) {
      &.cellContainer,
      .cellContainer {
        padding-right: 0;
      }
    }
  }

  tbody {
    .inter-medium();
    line-height: 22px;
    color: @grey-900;
    a {
      color: inherit;
    }

    tr {
      &.clickable {
        cursor: pointer;
        &:hover {
          background-color: @grey-50;
        }
      }
      &.disabled {
        color: @grey-400;

        td > * {
          color: @grey-400;
        }
      }
      &.expanded {
        background-color: @grey-50;
        color: @grey-800;

        td {
          border-color: @grey-100;
        }
        td:nth-child(2) {
          padding-left: 16px;
        }
      }
      &.highlighted {
        td {
          background-color: @primary-50;
        }
      }
      &.error {
        td {
          background-color: @error-50;
        }
      }
      &.warning {
        td {
          background-color: @warning-50;
        }
      }
      .micro-interaction(background-color, color;);
    }
  }

  thead {
    .inter-semibold();
    color: @grey-700;
    user-select: none;
    font-size: 11px;
    line-height: 16px;

    th {
      padding: 12px 0;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    &.left {
      justify-content: flex-start;
    }
    &.right {
      justify-content: flex-end;
    }
  }

  &.expandable {
    td,
    th {
      &:nth-child(2) {
        width: 1em;
        padding: 0;
        div {
          height: 100%;
          padding: 1em 0;
          width: 1em;
        }
      }
    }
  }
}

.expandableIcon {
  color: @grey-800;
}

.unexpandableIcon {
  color: @grey-300;
}

.sortingIndicator {
  display: flex;
  flex-direction: column;
  color: @grey-300;
  height: 24px;
  justify-content: center;
  margin-right: 4px;
  font-size: 8px;
  .active {
    color: @grey-800;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  padding-top: 12px;

  .pages {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
  }
  .pageItems {
    line-height: 24px;
    margin-right: 4px;
  }

  .button {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  .ellipsis {
    width: 24px;
    height: 24px;
    font-size: 14px;
    .inter-bold();
    display: flex;
    align-items: flex-end;
    padding-bottom: 4px;
    justify-content: center;
    margin-right: 4px;
  }
}
