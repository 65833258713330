:root {
  /* t10 colors */

  --color-white: 255 255 255;
  --color-black: 15 32 39;

  /* Core Colors */
  --color-light-mint: 232 250 236;
  --color-mint: 195 242 212;
  --color-blue-mint: 157 221 206;
  --color-dark-mint: 130 203 158;
  --color-jade-green: 65 164 103;
  --color-mint-dark-mode: 105 137 126;
  --color-dark-green: 0 77 64;
  --color-hot-green: 130 251 142;
  --color-deep-denim: 99 124 255;
  --color-cerulean: 23 92 211;
  --color-sapphire: 53 56 205;
  --color-azure-blue: 2 106 162;
  --color-indigo: 89 37 220;
  --color-midnight: 54 63 114;
  --color-bright-blue: 0 255 255;
  --color-gold: 156 131 68;
  --color-vibrant-magenta: 193 21 116;
  --color-slate: 15 49 56;

  /* Gray Scale */
  --color-gray-50: 249 250 251;
  --color-gray-200: 234 236 240;
  --color-gray-400: 152 162 179;
  --color-gray-500: 102 112 133;
  --color-gray-600: 71 84 103;
  --color-gray-800: 24 34 48;
  --color-gray-950: 12 17 29;

  /* Error Colors */
  --color-error-50: 254 243 242;
  --color-error-200: 254 205 202;
  --color-error-400: 249 112 102;
  --color-error-600: 217 45 32;
  --color-error-800: 145 32 24;
  --color-error-950: 85 22 12;

  /* Warning Colors */
  --color-warning-50: 255 250 235;
  --color-warning-200: 254 223 137;
  --color-warning-400: 253 176 34;
  --color-warning-600: 220 104 3;
  --color-warning-800: 147 55 13;
  --color-warning-950: 78 29 9;

  /* Success Colors */
  --color-success-50: 236 253 243;
  --color-success-200: 171 239 198;
  --color-success-400: 71 205 137;
  --color-success-600: 7 148 85;
  --color-success-800: 8 93 58;
  --color-success-950: 5 51 33;

  /* deprecated colors */
  --color-background: 255 255 255;
  --color-primary-900: 2 38 96;
  --color-primary-800: 2 56 143;
  --color-primary-700: 3 75 191;
  --color-primary-600: 4 110 239;
  --color-primary-500: 0 126 255;
  --color-primary-400: 97 167 252;
  --color-primary-300: 150 196 253;
  --color-primary-200: 202 226 254;
  --color-primary-100: 217 234 254;
  --color-primary-50: 241 247 254;

  --color-grey-900: 27 29 33;
  --color-grey-800: 54 59 65;
  --color-grey-700: 81 88 98;
  --color-grey-600: 108 118 130;
  --color-grey-500: 128 137 149;
  --color-grey-400: 152 161 171;
  --color-grey-300: 170 176 185;
  --color-grey-200: 186 194 204;
  --color-grey-100: 220 224 230;
  --color-grey-50: 242 243 245;

  --color-success-900: 6 40 20;
  --color-success-700: 17 120 59;
  --color-success-500: 26 183 89;
  --color-success-300: 121 232 165;
  --color-success-100: 192 246 214;

  --color-warning-900: 51 29 1;
  --color-warning-700: 153 86 3;
  --color-warning-500: 232 152 6;
  --color-warning-300: 250 189 78;
  --color-warning-100: 253 222 167;

  --color-error-900: 54 9 9;
  --color-error-700: 161 27 27;
  --color-error-500: 233 60 60;
  --color-error-300: 240 125 125;
  --color-error-100: 248 190 190;

  --color-badge-1: 34 183 150;
  --color-badge-2: 95 93 218;
  --color-badge-3: 233 136 77;
  --color-badge-4: 226 190 58;

  --color-accent-1: 0 63 92;
  --color-accent-2: 47 75 124;
  --color-accent-3: 102 81 145;
  --color-accent-4: 160 81 149;
  --color-accent-5: 212 80 135;
  --color-accent-6: 249 93 106;
  --color-accent-7: 255 124 67;
  --color-accent-8: 255 166 0;

  --color-default-font: 27 29 33;
}

:root.dark {
  --color-background: 18 18 18;
  --color-default-font: 255 255 255;
}