.dropdown-shadow {
  box-shadow: 0px 2px 4px rgba(27, 29, 33, 0.06),
    0px 8px 16px rgba(27, 29, 33, 0.08);
}

.popup-shadow {
  box-shadow: 0px 20px 48px rgba(27, 29, 33, 0.24);
}

.focusBorder(@color) {
  box-shadow: 0px 0px 0px 3px @color;
}
