@import "../../../style/border-radius.less";
@import "../../../style/typography.less";
@import "../../../style/shadows.less";
@import "../../../style/animation.less";

.menu {
  background-color: @background;
  .inter-medium();
  font-size: 12px;
  .border-radius-large();
  .dropdown-shadow();
  overflow: hidden;
  margin-top: 4px;
  z-index: 3; // to match z-modal
}

.menuItem {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: @grey-800;
  .micro-interaction(background-color, color;);
  &.active {
    background-color: @primary-100;
    color: @primary-600;
    &:hover {
      background-color: @primary-100;
    }
  }
  &.disabled {
    color: @grey-400;
    cursor: default;
  }
  &.underlined {
    border-bottom: 1px solid @grey-100;
  }
  &:hover {
    background-color: @grey-50;
  }
  cursor: pointer;
}
