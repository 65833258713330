@import "../../../style/border-radius.less";
@import "../../../style/typography.less";
@import "../../../style/shadows.less";

.stack {
  position: fixed;
  bottom: 0; // relies on the fact that <PageContainer> is always exactly 100% tall, and handles overflow itself
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 400px;
}

.snack {
  margin-bottom: 12px;
  margin-top: 0;
  padding: 12px;

  background: @background;
  border: 1px solid @grey-100;
  .dropdown-shadow();
  .border-radius-large();
  .inter-regular();
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;

  .icon {
    width: 32px;
    font-size: 14px;
    margin-right: 12px;

    &.info {
      color: @primary-500;
    }

    &.success {
      color: @success-500;
    }

    &.error {
      color: @error-500;
    }

    &.warning {
      color: @warning-500;
    }
  }
}
