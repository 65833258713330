@import "../../../style/border-radius.less";
@import "../../../style/shadows.less";
@import "../../../style/typography.less";

.actions {
  padding: 16px 12px 12px 12px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.title {
  display: flex;
  align-items: center;
  padding: 12px;
  background: @grey-50;
  border-bottom: 1px solid @grey-100;
  border-top-left-radius: @border-radius-large;
  border-top-right-radius: @border-radius-large;
}

.closeIcon {
  margin-left: auto;
  padding-right: 0px;
}

.contentContainer {
  display: flex;
  flex-direction: row;

  .content {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 12px;
  }
  .full {
    flex: 1;
  }
}

.rightPane {
  .contentContainer {
    height: 100%;
  }

  .content {
    height: 100%;
    overflow: auto;

    &.secondPane {
      border-left: 1px solid @grey-100;
    }
  }

  &.size_md {
    width: 400px;

    .content {
      width: 400px;
    }
  }

  &.size_lg {
    width: 702px;

    .content {
      width: 702px;
    }
  }

  &.size_xl {
    width: 1000px;

    .content {
      width: 1000px;
    }
  }
}

.popover {
  .popup-shadow();
  .border-radius-large();
  background: @background;
  .inter-medium();
  outline: none;

  &.withSecondPane {
    width: 800px;
  }

  &.rightPane {
    height: 100vh;
    overflow: auto;
    border-radius: 0;
  }

  &.medium {
    width: 400px;
  }

  &.large {
    width: 500px;
  }
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: fade(@grey-600, 40%);
  &.rightPaneOverlay {
    align-items: stretch;
    justify-content: flex-end;
  }
}
