@import "../../../style/border-radius.less";
@import "../../../style/typography.less";
@import "../../../style/shadows.less";

.container {
  overflow: visible !important;

  // Father forgive me for I have sinned. Tiny popover applies no classNames to the arrow container, but it
  // does consistently put it as the first child inside of another div, and this selector allows us to
  // select it, without selecting any other content...
  > div:first-child > div:first-child {
    // The ArrowContainer comoponent uses the traditional border trick (https://css-tricks.com/snippets/css/css-triangle/) to make a css triangle.
    // This has the downside of not supporting box shadows well. Instead we override the border configuration to
    // unset it, and then we instead create a box with a a white background+box shadow, and rotate it 45deg to create
    // the arrow.

    // The only additional complexity is we need to "cover up" the part of the box that overlaps the content.
    // We do that by settinga higher z-index in the .popoverContent below
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    background: @background;

    border: none !important;

    transform: rotate(45deg);

    .dropdown-shadow();
    z-index: 1;
  }
}

.popover {
  .dropdown-shadow();
  .border-radius-large();
  overflow: hidden;

  .popoverContent {
    width: 240px;
    background-color: @background;
    padding: 12px;
    padding-left: 36px;
    position: relative;
    z-index: 2;
    .inter-regular();
    font-size: 14px;
    line-height: 20px;

    .buttons {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
    }

    .icon {
      font-size: 14px;
      position: absolute;
      top: 14px;
      left: 12px;

      &.info {
        color: @primary-500;
      }

      &.success {
        color: @success-500;
      }

      &.error {
        color: @error-500;
      }

      &.warning {
        color: @warning-500;
      }
    }
  }
}
