.tooltip {
  background: rgb(var(--color-grey-900));
  border-radius: 4px;
  padding: 4px 8px;
  font-family: var(--font-family-inter);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: rgb(var(--color-white));
  max-width: 228px;
  pointer-events: none;
  margin: 4px;
}
.content {
  color: rgb(var(--color-white));
  margin: 0;
}
.container {
  display: flex;
}
.inline_container {
  display: inline-flex;
}
