.daysInputContainer {
  position: relative;
}
.days {
  color: rgb(var(--color-grey-900));
  position: absolute;
  pointer-events: none;
  height: 100%;
  align-items: center;
  top: 0;
}
.hidden {
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow: auto;
}
