@border-radius-large: 4px;
@border-radius-small: 2px;

.border-radius-large() {
  border-radius: @border-radius-large;
}

.border-radius-small() {
  border-radius: @border-radius-small;
}
