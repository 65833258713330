.icon svg {
  height: 100%;
  width: 100%;
  fill: currentColor;
}

:global(.ionicon-fill-none) {
  fill: none;
}

/**
 * only apply `stroke: currentColor` to icons which use strokes. Otherwise Safari on high-DPI screens
 * with specific resolutions will render the strokes even though they're not supposed to be rendered.
 */
:global(
    .ionicon:has(
        path[stroke-width],
        path[stroke-linejoin],
        path[stroke-linecap]
      )
  ) {
  stroke: currentColor;
}

:global(.ionicon-stroke-width) {
  stroke: currentColor;
  stroke-width: 32px;
}
