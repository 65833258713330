@import "../../../style/animation.less";
@import "../../../style/typography.less";

.tableContainer {
  margin-bottom: 12px;
}

.table {
  min-width: calc(100% + 24px);
  font-size: 12px;
  margin: 0 -12px;
  border-collapse: collapse;

  tbody:not(.noBottomBorder) {
    tr:last-child,
    .expanded:last-child {
      td {
        border-bottom: 1px solid @grey-100;
      }
    }
  }

  td {
    border-top: 1px solid @grey-100;
    .cell {
      display: flex;
      &.left {
        justify-content: flex-start;
        text-align: left;
      }
      &.right {
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  a.cellContainer {
    display: inline-block;
    min-width: 100%;
    text-decoration: none;
  }

  td,
  th {
    padding: 10px 10px;

    &:first-child,
    &:last-child {
      padding: 10px 0;
      width: 12px;
      min-width: 12px;
    }

    &:nth-child(2) {
      padding-left: 0;
    }
    &:nth-last-child(2) {
      padding-right: 0;
    }
  }

  tbody {
    .inter-medium();
    line-height: 22px;
    color: @grey-900;
    a {
      color: inherit;
    }

    tr {
      &.clickable {
        cursor: pointer;
        &:hover {
          background-color: @grey-50;
        }
      }
      &.expanded {
        background-color: @grey-50;
        color: @grey-800;

        td {
          border-color: @grey-100;
        }
        td:nth-child(2) {
          padding-left: 24px;
        }
      }
      .micro-interaction(background-color, color;);
    }
  }

  thead {
    .inter-semibold();
    color: @grey-700;
    user-select: none;
    font-size: 11px;
    line-height: 16px;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    &.left {
      justify-content: flex-start;
      text-align: left;
    }
    &.right {
      justify-content: flex-end;
      text-align: right;
    }
    &.clickable {
      cursor: pointer;
    }
  }
}

.sortingIndicator {
  display: flex;
  flex-direction: column;
  color: @grey-300;
  height: 24px;
  justify-content: center;
  margin-right: 4px;
  font-size: 8px;
  .active {
    color: @grey-800;
  }
}

.pagination {
  display: flex;
  flex-direction: row;
  padding-top: 8px;

  .pages {
    display: flex;
    flex-direction: row;
    margin-left: auto;
  }
  .pageItems {
    line-height: 24px;
    margin-right: 4px;
  }

  .button {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}
